<template>
  <div></div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  name: 'maps-new',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Maps' }])
  },
}
</script>
